import moment from 'moment'

export const methods = {
  converteKg(item) {
    return (parseFloat(item) * 1000).toFixed(0)
  },
  formatDate(date) {
    if (!date) return null

    return moment(date).format('DD/MM/YYYY')
  },
  getTempo(item) {
    if (item !== null) {
      const diferencaEmMs = new Date() - Date.parse(item)
      const segundos = diferencaEmMs / 1000
      return segundos
    } else {
      return 0
    }
  },
  getTempoEmPatio(item) {
    if (item.triado_em !== null) {
      const diferencaEmMs = new Date() - Date.parse(item.triado_em)
      const tempo = diferencaEmMs / 1000
      const horas = Math.floor(tempo / (60 * 60))
      const minutos = Math.floor((tempo % (60 * 60)) / 60)
      return `${horas}:${minutos}`
    } else {
      return '00:00'
    }
  },
  itemRowBackground: function (item) {
    const tempo_em_patio = this.getTempoEmPatio(item)
    if (Number(tempo_em_patio.split(':')[0]) >= 6) {
      return 'style-3'
    }
    return item.status_etapa == 'chamado' ? 'style-2' : 'style-1'
  },
}
