import { BasicPermissions } from '@/utils/permissions'

export const computed = {
  orderedListagem() {
    return this.data.sort((a, b) =>
      this.getTempo(a.triado_em) > this.getTempo(b.triado_em) ? -1 : 1
    )
  },
  canList() {
    return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
  },
}
