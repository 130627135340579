<template>
  <div class="mt-4">
    <v-data-table
      :headers="headers"
      dense
      :items-per-page="-1"
      hide-default-footer
      :items="orderedListagem"
      :item-class="itemRowBackground"
      class="elevation-1 small"
    >
      <template v-slot:[`item.status`]="{ item }">
        <!-- <v-chip color="primary">{{ item.status }}</v-chip> -->
        {{ item.status }}
      </template>
      <template v-slot:[`item.agendado_em`]="{ item }">
        {{
          item.agendado_em ? new Date(item.agendado_em).toLocaleString() : '-'
        }}
      </template>
      <template v-slot:[`item.cancelado_em`]="{ item }">
        {{
          item.cancelado_em ? new Date(item.cancelado_em).toLocaleString() : '-'
        }}
      </template>
      <template v-slot:[`item.senhas`]="{ item }">
        {{ item.senhas.length > 0 ? item.senhas[0].sequencia : '-' }}
      </template>
      <template v-slot:[`item.triado_em`]="{ item }">
        {{ item.triado_em ? new Date(item.triado_em).toLocaleString() : '-' }}
      </template>
      <template v-slot:[`item.data_cota`]="{ item }">
        <div v-if="item.data_cota">
          <span v-if="cota_dia_todo(item.data_cota)">{{
            item.data_cota.data_inicio | iso2br
          }}</span>
          <span v-else-if="cota_mesmo_dia(item.data_cota)">
            {{ mostra_cota_mesmo_dia(item.data_cota) }}</span
          >
          <span v-else>
            {{ item.data_cota.data_inicio | toBrazilianDateTime }}
            -
            {{ item.data_cota.data_fim | toBrazilianDateTime }}
          </span>
        </div>
        <div v-else>Não definida</div>
      </template>
      <template v-slot:[`item.saida_do_patio`]="{ item }">
        {{
          item.saida_do_patio
            ? new Date(item.saida_do_patio).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.chegada_terminal`]="{ item }">
        {{
          item.chegada_terminal
            ? new Date(item.chegada_terminal).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.data_pedido`]="{ item }">
        {{ item.data_pedido | date2br(item.data_pedido) }}
      </template>
      <template v-slot:[`item.tempoEmPatio`]="{ item }">
        {{ getTempoEmPatio(item) }}
      </template>
      <template v-slot:[`item.pedidos_agendados.numero_pedido`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.numero_pedido }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.numero_nfe`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.numero_nfe }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.produto`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.produto }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.sub_produto`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.sub_produto }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.quantidade`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ converteKg(pedido.quantidade) }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.embalagem`]="{ item }">
        <div class="div-pedido-info">
          <span v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.embalagem }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.nota_fiscal`]="{ item }">
        {{
          item.pedidos_agendados.length > 0
            ? item.pedidos_agendados[0].numero_nfe
            : '-'
        }}
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { props } from './_props'
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'
import {
  dateAgendamento,
  date2br,
  iso2br,
  toBrazilianDateTime,
} from '@/mixins/convertion'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'

const HORAS_TOLERADAS = 30

export default {
  mixins: [
    dateAgendamento,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  props,
  components: {},
  created() {
    this.HORAS_TOLERADAS = HORAS_TOLERADAS
  },
  data,
  computed,
  methods,
  watch: {
    'empresaAtual.public_id': {
      handler() {
        this.$emit('cleanData')
      },
    },
  },
  filters: { iso2br, toBrazilianDateTime },
}
</script>
<style>
.small tbody tr td {
  /* font-size: smaller !important; */
  font-size: x-small !important;
}

.style-1 {
  background-color: white;
}

.style-2 {
  background-color: #00aa9e;
  /* background-color: #23b14d; */
  color: white;
}
.style-3 {
  background-color: #f34848;
  color: white;
}
.style-3:hover {
  color: black !important;
}
</style>
<style lang="scss" scoped>
.status--cancelado {
  padding: 10px 15px;
  background: rgb(243, 72, 72);
  border-radius: 10px;
  color: white;
}

.status--triagem {
  padding: 10px 15px;
  background: rgb(64, 228, 64);
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.button-submit-orange {
  background: red;
}

.div-pedido-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.div-pedido-info p {
  margin: 5px 0;
}

div {
  margin-bottom: 5px;
}

p,
label {
  font: 1rem 'Fira Sans', sans-serif;
  text-transform: uppercase;
  margin-left: 8px;
}

input {
  min-width: 20px;
  min-height: 20px;
  margin-right: 5px;
}

legend {
  margin-bottom: 20px;
}
</style>
