var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1 small",attrs:{"headers":_vm.headers,"dense":"","items-per-page":-1,"hide-default-footer":"","items":_vm.orderedListagem,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.agendado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.agendado_em ? new Date(item.agendado_em).toLocaleString() : '-')+" ")]}},{key:"item.cancelado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cancelado_em ? new Date(item.cancelado_em).toLocaleString() : '-')+" ")]}},{key:"item.senhas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.senhas.length > 0 ? item.senhas[0].sequencia : '-')+" ")]}},{key:"item.triado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.triado_em ? new Date(item.triado_em).toLocaleString() : '-')+" ")]}},{key:"item.data_cota",fn:function(ref){
var item = ref.item;
return [(item.data_cota)?_c('div',[(_vm.cota_dia_todo(item.data_cota))?_c('span',[_vm._v(_vm._s(_vm._f("iso2br")(item.data_cota.data_inicio)))]):(_vm.cota_mesmo_dia(item.data_cota))?_c('span',[_vm._v(" "+_vm._s(_vm.mostra_cota_mesmo_dia(item.data_cota)))]):_c('span',[_vm._v(" "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_inicio))+" - "+_vm._s(_vm._f("toBrazilianDateTime")(item.data_cota.data_fim))+" ")])]):_c('div',[_vm._v("Não definida")])]}},{key:"item.saida_do_patio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saida_do_patio ? new Date(item.saida_do_patio).toLocaleString() : '-')+" ")]}},{key:"item.chegada_terminal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chegada_terminal ? new Date(item.chegada_terminal).toLocaleString() : '-')+" ")]}},{key:"item.data_pedido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date2br")(item.data_pedido,item.data_pedido))+" ")]}},{key:"item.tempoEmPatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item))+" ")]}},{key:"item.pedidos_agendados.numero_pedido",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(pedido.numero_pedido)+" ")])}),0)]}},{key:"item.pedidos_agendados.numero_nfe",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(pedido.numero_nfe)+" ")])}),0)]}},{key:"item.pedidos_agendados.produto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(pedido.produto)+" ")])}),0)]}},{key:"item.pedidos_agendados.sub_produto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(pedido.sub_produto)+" ")])}),0)]}},{key:"item.pedidos_agendados.quantidade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(_vm.converteKg(pedido.quantidade))+" ")])}),0)]}},{key:"item.pedidos_agendados.embalagem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.pedidos_agendados),function(pedido,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(pedido.embalagem)+" ")])}),0)]}},{key:"item.nota_fiscal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pedidos_agendados.length > 0 ? item.pedidos_agendados[0].numero_nfe : '-')+" ")]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }