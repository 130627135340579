<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="canList">
      <resumo :resumo="resumo" :permission="permission" />
      <listagem :headers="headers" :permission="permission" :data="items" />
    </div>
    <div v-else-if="!canList">
      <span class="red--text"
        >Você não tem permissão para visualizar as informações desta tela</span
      >
    </div>
  </div>
</template>

<script>
import Resumo from '@/components/patio/GerenciamentoServico/Resumo.vue'
import Listagem from './listagem/Componente'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    headers: {
      type: Array,
    },
    // tela: {
    //   type: String,
    //   required: true,
    // },
  },
  components: {
    Listagem,
    Resumo,
  },
  data() {
    return {
      loading: false,
      page_size: 1000,
      items: [],
      resumo: undefined,
      timeout: undefined,
    }
  },
  methods: {
    ...mapActions('patio', ['getAgendamentoListagem']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    getTempo(item) {
      const diferencaEmMs = new Date() - Date.parse(item)
      const segundos = diferencaEmMs / 1000
      return segundos
    },
    async getAgendamentos(from_refresh = false) {
      try {
        const status = ['chamado','em_patio']
        const params = {
          status_list: status.join(','),
        }
        const { results, count, resumo } = await this.getAgendamentoListagem({
          page: 1,
          page_size: this.page_size,
          proprietario: this.empresaAtual.public_id,
          ...params,
        })
        this.resumo = resumo
        if (count > this.page_size) {
          this.page_size = count
        }
        this.items = results.sort((a) => a.status == "TRIAGEM (CHAMADO PARA CARGA/DESCARGA)")
      } catch (error) {
        console.log(error)
        if (!from_refresh && error.refreshed) {
          return setTimeout(async () => {
            return await this.getAgendamentos(true)
          }, 3000)
        }
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async handleGetAgendamentos() {
      this.loading = true
      await this.getAgendamentos()
      this.loading = false
    },
  },
  computed: {
    ...mapState('auth', ['user', 'empresaAtual']),

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
  async mounted() {
    if (this.canList) {
      this.handleGetAgendamentos()
      const func = async () => {
        await this.handleGetAgendamentos()
      }
      const seconds = 60 // 60
      this.timeout = setInterval(func, seconds * 1000)
    }
  },
}
</script>
