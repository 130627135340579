export const props = {
  data: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    required: true,
  },
  permission: {
    type: String,
    required: true,
  },
}
