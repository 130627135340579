<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Resumo">
          <template slot="right">
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                @close="close"
                :localStorageKey="localStorageKey"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetAgendamentos"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>

          <listar-resumo
            :search="search"
            :headers="headersComputed"
            ref="listagem"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarResumo from '@/components/patio/Resumo/ListarResumo'
import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'

import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: {
    ListarResumo,
    IndicadoresGerencimentoServico,
  },
  data() {
    return {
      localStorageKey: 'headersResumo',
      dialog: false,
      showForm: false,
      showDocumentos: false,
      showComprovante: false,
      showComprovanteLaudo: false,
      showComprovanteAgendamento: false,
      showComprovanteServicoAdicional: false,
      showComprovantes: false,
      search: '',
      itensLaudo: [],
      page: {
        title: 'Gerenciamento de Serviços',
      },
      headers: [
        {
          habilited: true,
          block: false,
          text: 'Senha',
          divider: true,
          value: 'senhas',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Placa',
          divider: true,
          value: 'veiculo',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Status',
          divider: true,
          value: 'status',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Quantidade (KG)',
          divider: true,
          value: 'pedidos_agendados.quantidade',
          class: 'primary white--text',
        },

        {
          habilited: true,
          block: false,
          text: 'Motorista',
          divider: true,
          value: 'motorista',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Telefone',
          divider: true,
          value: 'motorista_tel',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Sub Produto',
          divider: true,
          value: 'pedidos_agendados.sub_produto',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Embalagem',
          divider: true,
          value: 'pedidos_agendados.embalagem',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Triado Em',
          divider: true,
          value: 'triado_em',
          class: 'primary white--text',
        },
        {
          habilited: false,
          block: false,
          text: 'Data da Cota',
          divider: true,
          value: 'data_cota',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Tempo em Patio',
          divider: true,
          value: 'tempoEmPatio',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Fornecedor',
          divider: true,
          value: 'fornecedor',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Terminal',
          value: 'terminal',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Cliente',
          divider: true,
          value: 'cliente',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Transportadora',
          divider: true,
          value: 'transportadora',
          class: 'primary white--text',
        },
        {
          habilited: true,
          block: false,
          text: 'Destinatario',
          divider: true,
          value: 'destinatario',
          class: 'primary white--text',
        },
        // {
        //   habilited: true,
        //   block: false,
        //   text: 'Modalidade',
        //   divider: true,
        //   value: 'modalidade',
        //   class: 'primary white--text',
        // },
      ],
      breadcrumbs: [
        {
          text: 'Pátio',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Gerenciamento de Serviços',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('agendamentos', ['agendamento']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.headers))
    },
  },

  async created() {
    this.setFiltrosGerenciamentoServicos()
  },

  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('patio', [
      'getAgendamentoGerenciamentoServico',
      'getItensLaudoCheckList',
    ]),

    setFiltrosGerenciamentoServicos() {
      const headersSaved = localStorage.getItem(this.localStorageKey)
      const headersSavedParsed = JSON.parse(headersSaved)

      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const headers_saved_map_habilited = headersSavedParsed
          .filter((item) => item.habilited)
          .map((item) => item.value)
        const headers_saved_map_not_habilited = headersSavedParsed
          .filter((item) => !item.habilited)
          .map((item) => item.value)
        this.headers = this.headers.map((item) => {
          let habilited = false
          if (headers_saved_map_habilited.includes(item.value)) {
            habilited = true
          } else {
            if (headers_saved_map_not_habilited.includes(item.value)) {
              habilited = false
            } else {
              // Para os novos
              habilited = item.habilited
            }
          }
          return {
            ...item,
            habilited,
          }
        })
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.headers))
      } else {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.headers))
      }
    },
    async getAgendamento(agendamento) {
      return await this.getAgendamentoGerenciamentoServico(agendamento)
    },
    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
    },
    close() {
      this.dialog = false
    },
  },
}
</script>
